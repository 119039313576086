<template>
  <div style="padding-bottom: 100px" class="updatePassword">
<!--    <rxNavBar title="shujv" androidOrIOSFlag="true" style="background-color: red" backColor="true"></rxNavBar>-->
    <rxNavBar :NoleftArrow="false" title="数据菜单"></rxNavBar>

    <div class="img_group">
            <img :src="headImg" class="oneImg">
            <div class="setPsd">数据菜单</div>
    </div>

    <div class="appointmentDetail">
      <div class="relevvant-detail relevvant-detail-border" @click="skipRenterContract" v-if="checkAuthList(authButtonsList,'portApproval')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">端口审批</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="alertPayCard" v-if="checkAuthList(authButtonsList,'editBankCard')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">修改工资卡</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="houseList" v-if="checkAuthList(authButtonsList,'houseList')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">房源列表</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="salaryApplication" v-if="checkAuthList(authButtonsList,'salaryApproval')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">工资审批</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="addressBook" v-if="checkAuthList(authButtonsList,'mailList')">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">通讯录</span>
      </div>
      <!--<div class="relevvant-detail relevvant-detail-border" @click="businessManual">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">业务类手册</span>
      </div>-->
    </div>
    <rxTabbar checked="dataMenu"></rxTabbar>
  </div>
</template>

<script>
import {
  Button, Calendar, DatetimePicker, NavBar, NumberKeyboard, Popup, Tabbar, TabbarItem, Toast
} from 'vant'
import rxTabbar from '../../components/rongxun/rx-tabbar/tabbar'
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import {
  checkAndroid,
  checkAuthList,
  checkIOS,
  getStaffId,
  globaluserId,
  openInWebview,
  responseUtil
} from "../../libs/rongxunUtil";
import {getStaffFuntionModelList, queryMyPageInit} from "../../getData/getData";
function backToAndroid(){
  window.himi.closeWindow();
}
export default {
  name: "dataMenu",
  components : {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
    rxTabbar
  },
  data (){
    return{
      active:5,
      userName:'',
      number:'617828',
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
      headImg: require("../../assets/images/head-quotation-white.png"),
      houseRelevantRenterImg: require("../../assets/images/house-relevant-renter.png"),  // 租客
      authButtonsList:[],
    }
  },
  mounted() {
    this.getStaffFuntionModelList()
    this.queryMyPageInit()
  },
  methods: {
    //跳转租客合同
    skipRenterContract(){
      this.$router.push({
        name: 'portApproveList',
        query: {
          fromType:'0',
          type: 1,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    //修改工资卡
    alertPayCard(){
      this.$router.push({
        name: 'staffPayCard',
        query: {

        }
      })
    },
    //房源列表
    houseList(){
      this.$router.push({
        name: 'houseList',
        query: {

        }
      })
    },
    //工资审批
    salaryApplication(){
      this.$router.push({
        name: 'salaryApproval',
        query: {

        }
      })
    },
    //通讯录
    addressBook(){
      this.$router.push({
        name: 'addressBook',
        query: {

        }
      })
    },
    //业务类手册
    businessManual(){
      this.$router.push({
        name: 'businessManual',
        query: {

        }
      })
    },
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    //权限方法
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'staffMore_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    queryMyPageInit () {
      var that = this
      let data = {}
      data.user_id = globaluserId()
      queryMyPageInit(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.userName)
          that.userName = response.data.data.userName
        })
      })
    },
    checkAuthList,
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

.changeStatusStyle {
  height: 2rem;
}
.confirmCancelOrdersText {
  margin-left: 35px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: bold
}

.cancelOrdersImg {
  float: left;
  margin: 20px 5px 0 15px;
  width: 15px;
  height: 15px;
}
.cnacelOrdersText {
  margin-top: 18px;
  font-size: 15px;
  font-weight: bold
}

.relevvant-detail {
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
  white-space: nowrap;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}


</style>